import React, { Component } from 'react';
import AngleLeftIcon from './assets/images/angle-left.svg';
import AngleRightIcon from './assets/images/angle-right.svg';
import MinusCircleIcon from './assets/images/minus-circle.svg';
import PlusCircleIcon from './assets/images/plus-circle.svg';

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLoaded: false,
      showDetails: false,
      height: null,
    }
  }

  template(content) {
    const getParameterByName = (name) => {
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };
    
    const plataform = getParameterByName('plataform');
    const isWebsite = plataform === 'website';

    const { effect, currentPage, numPages} = this.props;
    const { showDetails, height } = this.state;

    const video = content.video;
    const image = content.image;
    const isEffectLeft = effect === 'next';
    const imageLoaded = height !== null;

    let animation = '';
    if (imageLoaded) {
      animation = `animation-slide-in animation-slide-from-${isEffectLeft ? 'right' : 'left'}`;
    }

    return (
      <div className={isWebsite?"startup-wizard-template website": "startup-wizard-template"}>
        <div className="startup-wizard-cols">

          <div className={isWebsite?"col-1 col-left col-padding-right col-padding-left col-scroll center-content":"col-1 col-left col-padding-right col-padding-left col-scroll col-white-background white-content"}>
            <div className={isWebsite?"animation-fade": "col-height-300 animation-fade"}>
              <h1 style={{ color: content.title_color }}>{content.title}</h1>
              <h2>{content.subtitle}</h2>
              <h4>{content.body}</h4>
              {content.tray && (
                <div className="col-full">
                  <ul>
                    <li className="menu-toggle">
                      <button
                        type="button"
                        className="btn-link btn-justify margin-bottom bold"
                        onClick={() => this.setState({ showDetails: !showDetails })}
                      >
                        {content.tray.label}
                        <img alt="" src={ showDetails ? MinusCircleIcon : PlusCircleIcon } />
                      </button>
                      <div className={`toggle ${showDetails ? 'open' : ''}`}>
                        <p>{content.tray.body}</p>
                        {content.tray.link && (
                          content.tray.link.map((object, i) => {
                            const key = i;
                            return (
                              <a
                                key={key}
                                href={object.url}
                                className="link"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {object.label}
                              </a>
                            );
                          })
                        )}
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="bottom-container">
              <button
                style={isWebsite?{ backgroundColor: this.props.hasPrevius() ? '#337ab7' : '#b3b3b3', border: this.props.hasPrevius() ? '1px solid #337ab7' : 'none', pointerEvents: this.props.hasPrevius() ? 'auto' : 'none'} : { visibility: this.props.hasPrevius() ? 'visible' : 'hidden' }}
                className="btn btn-primary"
                onClick={this.props.previusPage}
              >
                <img alt="" src={AngleLeftIcon} />
              </button>
              <div className="">{currentPage} of {numPages}</div>
              {this.props.hasNext() && (
                <button
                  style={{ visibility: this.props.hasNext() ? 'visible' : 'hidden' }}
                  className="btn btn-primary"
                  onClick={() => this.props.nextPage()}
                >
                  <img alt="" src={AngleRightIcon} />
                </button>
              )}
            </div>
          </div>
          <div
            className="col-3 col-align-middle col-padding-right col-padding-left"
          >
            {image && (
              <img
                style={{ opacity: imageLoaded ? '1' : '0' }}
                className={`image-startup ${animation}`}
                ref={(el) => this.image = el}
                src={image}
                alt=""
                onLoad={(e) => {
                  const { offsetHeight } = e.target;
                  this.setState({ height: offsetHeight }, () => {
                    setTimeout(() => {
                      if(this.image) {
                        this.image.style.opacity = 0;
                        this.video.style.opacity = 1;
                        this.video.play();
                      }
                    }, 1500);
                  });
                }}
              />
            )}
            {height ? (
              <video
                ref={(el) => this.video = el}
                className="video-startup"
                muted
                playsInline
              >
                <source src={video} type="video/mp4" />
              </video>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
