import PageTemplate from '../PageTemplate';
import Image from './assets/images/Dashboards.jpg';
import React from 'react';
import Video from '../../videos/Dashboards.mp4';

class Dashboards extends PageTemplate {
  render() {
    return this.template({
      title_color: 'hsl(321, 84%, 38%)',
      title: 'Dashboards',
      subtitle: 'Surprise your customers with eye-catching dashboards',
      image: Image,
      video: Video,
      body: (<React.Fragment>Build great dashboards using a wide variety of widgets that enable you to create virtually any dashboard you desire, tailored specifically to your needs. In mere minutes, you are able to create beautiful dashboards that can be updated in real-time, with widgets that can showcase <b>maps</b>, <b>images</b>, <b>gauges</b>, <b>videos</b>, <b>graphs</b>, and much more.</React.Fragment>),
      tray: {
        label: 'Use and create templates',
        body: <span>Use the distribution feature to share and use templates of beautiful dashboards.</span>,
        link: [{
          label: 'Learn more about distributing dashboards',
          url: 'https://help.tago.io/portal/en/kb/articles/518-distributing-dashboards'
        }]
      }
    })
  }
}

export default Dashboards;
