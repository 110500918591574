import React from 'react';
import PageTemplate from '../PageTemplate';
import Image from './assets/images/Deploy.jpg';
import Video from '../../videos/Deploy.mp4';

class Deploy extends PageTemplate {
  render() {
    return this.template({
      title_color: 'rgb(120, 90, 255)',
      title: 'Deploy',
      subtitle: 'Launch your application for your end-users',
      image: Image,
      video: Video,
      body: <span>Through <b>TagoRUN</b> you can easily deploy your own platform with a wide variety of different customization options, ranging from color and logo management, to customized domains and emails, all the way to having full control of the end-users that access your platform.</span>,
      tray: {
        label: 'Take full advantage of RUN with a mobile app',
        body: (
          <React.Fragment>
            When you deploy with TagoRUN, you are also able to launch a <b>mobile app</b> alongside your brand that is exceptionally versatile and customizable.
          </React.Fragment>
        ),
        link: [{
          label: 'Learn more about mobile apps',
          url: 'https://help.tago.io/portal/en/kb/articles/167-mobile-apps'
        }]
      }
    })
  }
}

export default Deploy;
