import React, { Component } from 'react';
import AngleLeftIcon from './assets/images/angle-left.svg';

class Page extends Component {

  sendMessage(type) {
    window.parent.postMessage(`tago-open-${type}`, '*');
  }

  render() {
    return (
      <div className="startup-wizard-finish">
        <div className="text-center">
          <h1 className="bold">What to do next...</h1>
        </div>
        <div className="startup-wizard-cols">
          <div className="col-auto col-bottom">
            <div className="card card-primary card-click" onClick={(e) => this.sendMessage('new-device')}>
              <h3>Add a device and start building your applications</h3>
              <div className="card-footer">
                <button type="button" className="btn-link link btn-white">Start building</button>
              </div>
            </div>
          </div>
          <div className="col-auto col-top">
            <div className="card card-dark card-click" onClick={(e) => this.sendMessage('explore')}>
              <h3>Check out our cases and install demos using the "Explore"</h3>
              <div className="card-footer">
                <button type="button" className="btn-link link btn-white">Explore demos</button>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-container">
          <button disabled={this.props.hasPrevius() ? false : true} className="btn btn-primary" onClick={() => this.props.previusPage()}>
            <img alt="" src={AngleLeftIcon} />
          </button>
          <button
            disabled={this.props.hasNext() ? false : true}
            className="btn disabled"
            onClick={() => this.props.nextPage()}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

export default Page;
