import React from 'react';
import PageTemplate from '../PageTemplate';
import Image from './assets/images/Device.jpg';
import Video from '../../videos/Device.mp4';

class Devices extends PageTemplate {
  render() {
    return this.template({
      title_color: '#118c8c',
      title: 'Devices',
      subtitle: 'Connect to any device',
      image: Image,
      video: Video,
      body: 'Start your application by simply adding a device, and a device-token will be automatically generated for you to authenticate your real device. You can start picking one from our list of hundred of devices, or by manually customizing all of the fields to communicate with anything, including web services, connectors, and analytics.',
      tray: {
        label: 'No real devices on hand? Use our Emulator',
        body: (
          <React.Fragment>
            The <b>Device Emulator</b> allows you to immediately build applications by sending data from your account as if it came from a real device.
          </React.Fragment>
        ),
        link: [{
          label: 'Learn how to use Device Emulator',
          url: 'https://help.tago.io/portal/en/kb/articles/95-device-emulator'
        }]
      }
    })
  }
}

export default Devices;
