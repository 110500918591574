import PageTemplate from '../PageTemplate';
import Image from './assets/images/DataManagement.jpg';
import React from 'react';
import Video from '../../videos/DataManagement.mp4';

class DataManagement extends PageTemplate {
  render() {
    return this.template({
      title_color: 'rgb(85, 107, 47)',
      title: 'Data Management',
      subtitle: 'Easily manage all of your data & files',
      image: Image,
      video: Video,
      body: <React.Fragment>All of the data from your devices are stored in <b>Buckets</b>, and any files you may need are stored in <b>Files</b>. Every time a device is added, TagoIO creates a bucket for it. You can easily visualize and manage each variable stored from the Bucket list - no need to learn any database structure.</React.Fragment>,
      tray: {
        label: 'Define a period of data retention',
        body: <React.Fragment>You have full control of the period that data will be kept for in <b>Buckets</b>.</React.Fragment>,
        link: [{
          label: 'Learn more about data management',
          url: 'https://help.tago.io/portal/en/kb/articles/2-buckets'
        }]
      }
    })
  }
}

export default DataManagement;
