import React, { Component } from 'react';

import Device from './Pages/Steps/Device';
import Deploy from './Pages/Steps/Deploy';
import DataManagement from './Pages/Steps/DataManagement';
import Analysis from './Pages/Steps/Analysis';
import Action from './Pages/Steps/Actions';
import Dashboard from './Pages/Steps/Dashboards';
import FinalPage from './Pages/FinalPage';
import WelcomePage from './Pages/WelcomePage';

import './App.scss';

const pagesAdmin = [
  WelcomePage,
  Device,
  Dashboard,
  DataManagement,
  Analysis,
  Action,
  Deploy,
  FinalPage,
];

const pagesWebsite = [
  Device,
  Dashboard,
  DataManagement,
  Analysis,
  Action,
  Deploy,
];

const getParameterByName = (name) => {
  const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const plataform = getParameterByName('plataform');
const isAdmin = plataform === 'admin';

const pages = isAdmin?pagesAdmin : pagesWebsite

class App extends Component {
  state = {
    page: 1,
    effect: 'next',
  };

  nextPage() {
      this.setState({ page: this.state.page + 1, effect: 'next' });
    }

  previusPage() {
    if (this.state.page >= 2){
      this.setState({ page: this.state.page - 1, effect: 'prev' });
    }
  }

  hasNext() {
    if (this.state.page >= pages.length) return false;
    return true;
  }

  hasPrevius() {
    if (this.state.page <= 1) return false;
    return true;
  }

  render() {
    const { effect, page } = this.state;
    const ActualPage = pages[page - 1];
    if (!ActualPage) {
      return <p>No Actual Page</p>;
    }
    return <ActualPage
      effect={effect}
      currentPage={isAdmin ? page - 1 : page}
      numPages={isAdmin ? pages.length - 2 : pages.length}
      nextPage={this.nextPage.bind(this)}
      previusPage={this.previusPage.bind(this)}
      hasNext={this.hasNext.bind(this)}
      hasPrevius={this.hasPrevius.bind(this)}
      />
  }
}

export default App;
