import React, { Component } from 'react';
import UserIcon from './assets/images/user.svg';
import UsersIcon from './assets/images/users.svg';

class Page extends Component {

  sendMessage(type) {
    window.parent.postMessage(`tago-${type}`, '*');
  }

  getParameterByName(name) {
    try {
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    } catch (ex) {
      return '';
    }
  }

  render() {
    const name = this.getParameterByName('name') || 'developer';
    return (
      <div className="startup-wizard-welcome">
        <div className="text-center">
          <h1 className="bold dark">Hi {name}, welcome to TagoIO</h1>
          <h4>Please choose one option</h4>
        </div>
        <div className="startup-wizard-cols">
          <div className="col-auto col-bottom">
            <div className="card card-secondary card-click" onClick={(e) => this.props.nextPage()}>
              <img alt="" src={UserIcon} />
              <h3 className="text-center">I’m new at TagoIO</h3>
              <div className="card-footer">
                <button type="button" className="btn-link link">
                  Introduce me the concepts first
                </button>
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="card card-secondary card-click" onClick={(e) => this.sendMessage('dev-experience')}>
              <img alt="" src={UsersIcon} />
              <h3 className="text-center">I already know  TagoIO</h3>
              <div className="card-footer">
                <button type="button" className="btn-link link">
                  Skip the introduction
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
