import PageTemplate from '../PageTemplate';
import Image from './assets/images/Actions.jpg';
import React from 'react';
import Video from '../../videos/Actions.mp4';
class Actions extends PageTemplate {
  render() {
    return this.template({
      title_color: '#e6431e',
      title: 'Actions',
      subtitle: 'Synchronize events and take actions',
      image: Image,
      video: Video,
      body: <React.Fragment>Send <b>SMS</b>, <b>e-mails</b>, <b>notifications</b> and <b>data</b> back to yours device, or even run scripts in the Analysis triggered by your own rules. Combining Actions with Analysis capabilities, there are limitless possibilities to create any event condition, vastly beyond the simple if/else condition.</React.Fragment>,
    })
  }
}

export default Actions;
