import PageTemplate from '../PageTemplate';
import Image from './assets/images/Analysis.jpg';
import React from 'react';
import Video from '../../videos/Analysis.mp4';

class Analysis extends PageTemplate {
  render() {
    return this.template({
      title_color: '#294E80',
      title: 'Analysis',
      subtitle: 'Create more value by adding analytics & flow automation',
      image: Image,
      video: Video,
      body: (
        <span>
          <b>Analysis</b> is a powerful feature that developers can use to add more value to their solutions by implementing scripts that manipulate data from any device in real-time. From simple calculations, to report generation, to machine learning implementations, there's virtually no limit to what you can do with these scripts.
        </span>
      ),
      tray: {
        label: 'Code scripts in any language',
        body: 'Integrate scripts coded in any language with your application at TagoIO when you run them on your own servers. If you want to run them on TagoIO, simply code them in Java Script.',
        link: [{
          label: 'Learn how Analysis works',
          url: 'https://help.tago.io/portal/en/kb/articles/29-analysis-overview'
        }]
      }
    })
  }
}

export default Analysis;
